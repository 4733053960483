const styles = () => ({
	companyNameInput: {
		maxWidth: '203px',
		border: '1px solid #70707045',
		borderRadius: '5px',
		background: '#FAFBFD',
	},
});

export default styles;
