import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// MUI Pro License
import { LicenseInfo } from '@mui/x-license-pro';

// Auth provider
import { Auth0Provider } from '@auth0/auth0-react';

// Roles & permission management
import { PermifyProvider } from '@permify/react-role';

// Redux
import store from './redux/store';

// Pages
import App from './App';

// Styles
import './index.css';

// Other
import reportWebVitals from './reportWebVitals';
import { LoaderStateManager } from './services/hoc/LoaderStateManager';
import history from './services/history';

const root = ReactDOM.createRoot(document.getElementById('root'));

const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.returnTo ? appState.returnTo : window.location.pathname
	);
};

const providerConfig = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	onRedirectCallback,
	authorizationParams: {
		redirect_uri: window.location.origin,
		audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		scope: process.env.REACT_APP_AUTH0_SCOPE,
		useRefreshTokens: true,
	},
};

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<PermifyProvider>
				<LoaderStateManager>
					<Auth0Provider {...providerConfig}>
						<React.StrictMode>
							<App />
						</React.StrictMode>
					</Auth0Provider>
				</LoaderStateManager>
			</PermifyProvider>
		</BrowserRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: review for performance monitoring
reportWebVitals();
